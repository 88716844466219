* {
  box-sizing: border-box
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato';
  color: #222222;
}

button {
  /* Because material-ui resets it to arial */
  font-family: 'Lato' !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Time picker */
.rc-time-picker-panel {
  z-index: 3000;
}

/* React Router adds weird colors */
a:link{
  color: inherit !important;
}
a:visited{
  color:inherit !important;
}
a:hover{
  color:inherit !important;
}
a:focus{
  color:inherit !important;
}
a:active{
  color:inherit !important;
}
/*Font Stylings*/

.title {
  font-size: 24px !important;
}

.subtitle {
  font-size: 20px !important;
}

.body {
  font-size: 16px !important;
}

.caption {
  font-size: 13px !important;
}
